import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import LikeButton from 'containers/LikeButton';
import { abbreviateNumber } from 'utils/formatters';

import {
    ICurrentMusic,
    IPlanObject, ISlideDialog 
} from 'interfaces';

import './SideBar.scss';

interface ISideBarProps {
    className?: string;
    currentTrack: IPlanObject;
    currentMusic: IPlanObject | ICurrentMusic;
    // eslint-disable-next-line no-unused-vars
    setSlideDialogOpen: (arg: ISlideDialog) => any;
    [key: string]: any;
}


function SideBar(props: ISideBarProps) {
    const {
        className = '',
        currentTrack = {},
        currentMusic = {} as ICurrentMusic,
        setSlideDialogOpen,
    } = props;

    const {
        views = 0,
    } = currentTrack;

    const handleMoreButtonClick = ()=> {

        setSlideDialogOpen({
            musicId: currentMusic.musicId,
            playlistId: currentMusic.playlistId,
            index: currentMusic.index,
            isOpen: true,
        });
    };

    const classNames = `side-bar ${className}`.trim();

    return (
        <ul className={classNames}>
            <li>
                <IconButton 
                    className="btn-more"
                    onClick={handleMoreButtonClick}
                    aria-label="more"
                >
                    <AddCircleOutlineIcon />
                </IconButton>
            </li>
            <li>
                <LikeButton />
            </li>
            <li>
                <MusicNoteIcon />
                <small>
                    {abbreviateNumber(views)}
                </small>
            </li>
        </ul>
    );
}

export default SideBar;
