/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
import {
    useEffect,
    useState 
} from 'react';
import { 
    Link,
    useNavigate,
    useLocation  
} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import WiseTreeLogo from "components/WiseTreeLogo";
import useSticky from 'hooks/useSticky';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DrawerMenu from 'containers/DrawerMenu';
import { 
    isDesktop,
    isMobile
} from 'utils';

import {
    PLAYLIST_BY_ID,
    CREATE,
    SIGN_IN,
    MY_SONGX,
    PROFILE,
    REWARDS,
} from 'constants/routes';


import './MainHeader.scss';
import { IPlanObject } from 'interfaces';

interface IMainHeaderProps {
    className?: string;
    isDrawerOpen: boolean;
    // eslint-disable-next-line no-unused-vars
    toggleDrawer: (arg: boolean) => any;
}

const showBackButtonRoutes = [PLAYLIST_BY_ID, CREATE, SIGN_IN, MY_SONGX, PROFILE, REWARDS];

function MainHeader(props: IMainHeaderProps) {
    const {
        className = '',
        isDrawerOpen,
        toggleDrawer = ()=>{},
    } = props;
    
    const [stickyRef, isSticky] = useSticky({ 
        buffer: 5,
        enable: isDesktop,
    });

    const [locationState, setlocationState] = useState<IPlanObject>();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        pathname,
    } = useLocation();

    useEffect(() => {
        if (location && location.state) {
            setlocationState(location.state);
        }
    }, [location]);
    

    const stickyCss = isSticky
        ? 'main-header--sticky'
        : '';

    let showBackButton = false;

    if (pathname !== '/') {
        const showBackButtonRoute = showBackButtonRoutes.find((route)=> pathname.indexOf(route) >= 0);
        showBackButton = !!showBackButtonRoute;
    }

    const handleDrawer = ()=> {
        toggleDrawer(!isDrawerOpen);
    };

    const handleBack = ()=> {
        if (locationState) {
            if (locationState.backToHome) {
                navigate('/');
                return;
            }
        }
        navigate(-1);
    };

    const classNames = `main-header ${isDesktop ? 'main-header--desktop' : ''} ${className}`.trim();

    const logoLink = (
        <Link to="/">
            <WiseTreeLogo />
        </Link>
    );

    const mobileHeader = isMobile && (
        <>
            {!showBackButton && (
                <IconButton 
                    className="main-header__btn-drawer"
                    onClick={handleDrawer}
                >
                    <MenuIcon />
                </IconButton>
            )
            }
            { showBackButton && (
                <IconButton 
                    className="main-header__btn-back"
                    onClick={handleBack}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>
            )}
            {logoLink}
        </>
    );

    const desktopHeader = isDesktop && (
        <Container 
            className="main-header__desktop-container"
            maxWidth="lg"
        >
            <Grid 
                container
                spacing={0}
            >
                <Grid 
                    className="main-header__logo" 
                    size={{ sm: 2 }}
                >
                    {logoLink}
                </Grid>
                <Grid size={{ sm: 10 }}>
                    <DrawerMenu />
                </Grid>
            </Grid>
        </Container>
    );

    return (
        <div className={stickyCss}>
            <header className={classNames} ref={stickyRef}>
                {mobileHeader}
                {desktopHeader}
            </header>
        </div>
    );
}

export default MainHeader;
