import { connect } from 'react-redux';
import { setSlideDialogOpen, } from 'actions/music';
import SideBar from 'components/MusicPlayer/SideBar';

import {
    IRootState,
    ISlideDialog
} from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    slideDialog: state.music.slideDialog,
    currentMusic: state.music.currentMusic,
});

const mapDispatchToProps = (dispatch: any) => ({ setSlideDialogOpen: (data: ISlideDialog) => dispatch(setSlideDialogOpen(data)), });

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(SideBar);
