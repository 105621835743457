import { connect } from 'react-redux';
import {
    updateView, 
    setSlideDialogOpen,
    updatedMusicInPlaylist
} from 'actions/music';
import AddSong from 'components/Forms/Playlist/AddSong';

import {
    IPlanObject, 
    IRootState,
    ISlideDialog
} from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    currentMusic: state.music.currentMusic,
    slideDialog: state.music.slideDialog,
    isAuthenticated: !!state.account?.authenticated,
    userPlaylists: state.account?.playlists || {},
});

const mapDispatchToProps = (dispatch: any) => ({ 
    updateView: (data: IPlanObject) => dispatch(updateView(data)),
    setSlideDialogOpen: (data: ISlideDialog) => dispatch(setSlideDialogOpen(data)),
    updatedMusicInPlaylist: (data: IPlanObject) => dispatch(updatedMusicInPlaylist(data)),
});

export default  connect(mapStateToProps, mapDispatchToProps)(AddSong);
